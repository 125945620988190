import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-meal-total-bites" }
const _hoisted_2 = { class: "bites-list" }
const _hoisted_3 = { class: "bite-item" }
const _hoisted_4 = { class: "bite-item_name" }
const _hoisted_5 = { class: "bite-item_total" }
const _hoisted_6 = { class: "bite-item" }
const _hoisted_7 = { class: "bite-item_name" }
const _hoisted_8 = { class: "bite-item_total" }
const _hoisted_9 = { class: "bite-item" }
const _hoisted_10 = { class: "bite-item_name" }
const _hoisted_11 = { class: "bite-item_total" }
const _hoisted_12 = { class: "total-calories" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  class: "meal-completed-panel meal-completed"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "meal-completed-panel meal-not-completed"
}
const _hoisted_17 = ["src"]

import { MenuMealDto, NutritionalPlan } from '@/models/NutritionalPlan';
import { computed, ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'TotalBites',
  props: {
  mealMenu: {
    type: Object as () => MenuMealDto,
    required: true,
  },
  nutritionalPlan: {
    type: Object as () => NutritionalPlan,
    required: true,
  },
  showCompletedMealTag: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const iconsUrlBase = ref(window.location.origin + '/assets/icon/nutrition/');
const iconCompleted = ref(iconsUrlBase.value + 'completed.svg');
const iconNotCompleted = ref(iconsUrlBase.value + 'not-completed.svg');

const totalFoodsCalories = computed(() => {
  let total = 0;
  const meal = props.nutritionalPlan.meals.find((meal) => props.mealMenu.mealType === meal.mealType);

  meal?.mealDetails.forEach((mealDetails) => {
    total += mealDetails.calories;
  });

  return total.toFixed(0);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('nutrition.menu.total-bites-title')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "icon-proteins" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.PROTEIN')), 1)
        ]),
        _createElementVNode("p", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t('nutrition.menu.total-healzy-bites')) + " ", 1),
          _createElementVNode("span", null, _toDisplayString(__props.mealMenu.proteinBites ? __props.mealMenu.proteinBites : '-'), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "icon-fats" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.FAT')), 1)
        ]),
        _createElementVNode("p", _hoisted_8, [
          _createTextVNode(_toDisplayString(_ctx.$t('nutrition.menu.total-healzy-bites')) + " ", 1),
          _createElementVNode("span", null, _toDisplayString(__props.mealMenu.fatBites ? __props.mealMenu.fatBites : '-'), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "icon-hc" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.CARBS')), 1)
        ]),
        _createElementVNode("p", _hoisted_11, [
          _createTextVNode(_toDisplayString(_ctx.$t('nutrition.menu.total-healzy-bites')) + " ", 1),
          _createElementVNode("span", null, _toDisplayString(__props.mealMenu.carbohydratesBites ? __props.mealMenu.carbohydratesBites : '-'), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('nutrition.menu.total-calories')) + " ", 1),
        _createElementVNode("span", null, _toDisplayString(totalFoodsCalories.value) + " " + _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1)
      ])
    ]),
    (__props.showCompletedMealTag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (__props.mealMenu.completed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("img", {
                  src: iconCompleted.value,
                  alt: ""
                }, null, 8, _hoisted_15),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.meal-completed')), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("img", {
                  src: iconNotCompleted.value,
                  alt: ""
                }, null, 8, _hoisted_17),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.meal-not-completed')), 1)
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})