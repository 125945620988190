enum Objectives {
  BALANCED_LIFESTYLE = 'BALANCED_LIFESTYLE',
  EAT_HEALTHY = 'EAT_HEALTHY',
  QUICK_EASY_COOKING = 'QUICK_EASY_COOKING',
  WEEKLY_PLANNING_HELP = 'WEEKLY_PLANNING_HELP',
}

enum Nutritionist {
  YES = 'YES',
  NO = 'NO',
}

enum Intolerances {
  NO = 'NO',
  CELERY = 'CELERY',
  CRUSTACEANS = 'CRUSTACEANS',
  DAIRY = 'DAIRY',
  EGGS = 'EGGS',
  GLUTEN = 'GLUTEN',
  LUPINE = 'LUPINE',
  MOLLUSKS = 'MOLLUSKS',
  NUTS = 'NUTS',
  PEANUTS = 'PEANUTS',
  SESAME = 'SESAME',
  SOY = 'SOY',
  SULPHITE = 'SULPHITE',
  LACTOSE = 'LACTOSE',
}

enum Foods {
  MILK_AND_DERIVATES = 'MILK_AND_DERIVATES',
  RED_MEAT = 'RED_MEAT',
  WHITE_MEAT = 'WHITE_MEAT',
  FISH = 'FISH',
  PROTEIN_PLANT = 'PROTEIN_PLANT',
  EGGS = 'EGGS',
  POTATOES = 'POTATOES',
  LEGUMES = 'LEGUMES',
  NUTS = 'NUTS',
  VEGETABLES = 'VEGETABLES',
  FRUITS = 'FRUITS',
  CEREALS_AND_DERIVATES = 'CEREALS_AND_DERIVATES',
  SUGAR_AND_CANDY = 'SUGAR_AND_CANDY',
  GREASE = 'GREASE',
}

interface PatientInfoItem {
  id: string;
  text: string;
  value: boolean;
}

export { Objectives, Nutritionist, Intolerances, Foods, PatientInfoItem };
