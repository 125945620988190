import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.$t('nutrition.menu.modal-alternative-header'),
    modal: true,
    class: "p-fluid"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('nutrition.menu.button-create-alternative'),
        class: "p-button p-component p-button-primary p-button-medium",
        loading: _ctx.submitting,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.modal-alternative-title', { mealName: _ctx.mealName })), 1)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}