
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { ServiceError } from '@/services/util/ServiceError';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';

export default defineComponent({
  emits: ['created-alternative-meal'],
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const showDialog = ref(false);
    const submitting = ref(false);
    const selectedMealId = ref('');
    const selectedDate = ref('');
    const nutritionalPlanId = ref('');
    const mealName = ref('');
    const day = ref();

    const openDialog = (
      mealId: string,
      date?: string,
      nutritionalPlan?: string,
      nameOfMeal?: string,
      dayNumber?: number,
    ) => {
      selectedMealId.value = mealId;
      selectedDate.value = date ?? '';
      nutritionalPlanId.value = nutritionalPlan ?? '';
      mealName.value = nameOfMeal ?? '';
      showDialog.value = true;
      day.value = dayNumber;
    };

    const onSubmit = async () => {
      if (!selectedMealId.value) {
        return;
      }
      submitting.value = true;
      try {
        if (nutritionalPlanId.value) {
          await updateAlternativeMealOnDefaultNutritionalPlan();
        } else if (!selectedDate.value) {
          await updateAlternativeMealOnDiet();
        } else {
          await updateAlternativeMealOnMenuDay();
        }
        emit('created-alternative-meal');
        toast.add({
          severity: 'success',
          summary: t('nutrition.menu.alternative-created'),
          life: 3000,
        });
        showDialog.value = false;
      } catch (error) {
        handleServiceError(error);
      } finally {
        submitting.value = false;
      }
    };

    async function updateAlternativeMealOnDefaultNutritionalPlan() {
      await nutritionalPlanService.updateAlternativeMealOnDefaultNutritionalPlan(
        nutritionalPlanId.value,
        selectedMealId.value,
        props.patientId,
      );
    }

    async function updateAlternativeMealOnDiet() {
      await nutritionalPlanService.updateAlternativeMealOnDiet(props.patientId, day.value, selectedMealId.value);
    }

    async function updateAlternativeMealOnMenuDay() {
      await nutritionalPlanService.updateAlternativeMealOnMenuDay(
        props.patientId,
        day.value,
        selectedMealId.value,
        selectedDate.value,
      );
    }

    function handleServiceError(error: any) {
      if (error instanceof ServiceError) {
        toast.add({ severity: 'error', summary: t('common.error'), detail: error.message });
      }
    }

    return {
      mealName,
      submitting,
      showDialog,
      openDialog,
      onSubmit,
    };
  },
});
